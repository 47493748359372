import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { graphql } from "gatsby";
import Articles from "../components/articles";
import Pickup from "../components/pickup"
import Card from "../components/card"
import Banner from "../components/banner";
import styled from "styled-components";

// const InfoContainer = styled.div`
//   display: grid;
//   grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
//   row-gap: 16px;
//   justify-items: center;
//   margin: 16px 0 40px 0;
// `;
const PickupMobileContainer = styled.div`
  display: block;
  @media (min-width: 950px) {
    display: none;
  } 
`;

const IndexPage = ({ data }) => {
  let pickup = null
  if (data.pickup.edges.length > 0) {
    pickup = (
      <div>
        <Pickup data={data.pickup.edges[0].node}/>
        <PickupMobileContainer>
          <Articles data={data.pickup} title={false}/>
        </PickupMobileContainer>
      </div>
    )
  }
  return (
    <Layout>
      <SEO title="Home" keywords={[`働き方`, `リモートデスクトップ`, `時短`]} />
      {/*<p*/}
      {/*  style={{*/}
      {/*    fontSize: "11px",*/}
      {/*    textAlign: "center",*/}
      {/*    margin: "20px"*/}
      {/*  }}*/}
      {/*>*/}
      {/*  お知らせ：こちらにお知らせが入ります。*/}
      {/*</p>*/}
      {pickup}
      <Articles data={data.newly} title="今週の新着" />
      <Articles title="特集" />
      {/*<RankingBlock title="人気ランキングのコンテンツがこちら" />*/}
      {/*<InfoContainer>*/}
      {/*</InfoContainer>*/}
      <Banner/>
    </Layout>
  );
}

export default IndexPage;

export const query = graphql`
query {
    newly: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC },
      filter: { 
        fields: {
          reserved: { eq: false },
          attributes: { in: ["new"] }
        }
      }) {
      edges {
        node {
          id
          fields {
            thumbnail {
              childImageSharp {
                fixed(width: 304, height: 240, cropFocus: CENTER, quality: 90) {
                  width
                  height
                  src
                  srcSet
                }   
              }
            }
            title
            date(formatString: "YYYY年M月D日")
            author
            slug
            summary
            attributes
            tags
          }
          excerpt
        }
      }
    }
    pickup: allMarkdownRemark(
      limit: 1,
      sort: { fields: [frontmatter___date], order: DESC },
      filter: {
        fields: {
          reserved: { eq: false },
          attributes: { in: ["pickup"] }
        }
      }) {
        edges {
          node {
            id
            fields {
              thumbnail {
                childImageSharp {
                  fixed(width: 304, height: 240, cropFocus: CENTER, quality: 90) {
                    width
                    height
                    src
                    srcSet
                  }
                }
                desktop: childImageSharp {
                  fixed(width: 500, height: 320, cropFocus: CENTER, quality: 90) {
                    width
                    height
                    src
                    srcSet
                  }
                }
              }
              slug
              title
              date(formatString: "YYYY年M月D日")
              author
              summary
              attributes
              tags
            }
          }
        }
    }
  }
`

  //   feature: allMarkdownRemark(
  //     sort: { fields: [frontmatter___date], order: DESC },
  //     filter: { frontmatter: { firstOfSeries: { eq:"yes" }}}) {
  //     totalCount
  //     edges {
  //       node {
  //         id
  //         frontmatter {
  //           title
  //           date(formatString: "YY年M月D日")
  //           image {
  //             childImageSharp {
  //               fixed(width: 304, height: 240, cropFocus: CENTER, quality: 90) {
  //                 width
  //                 height
  //                 src
  //                 srcSet
  //               }
  //             }
  //           }
  //           summary
  //           firstOfSeries
  //           tags
  //         }
  //         fields {
  //           slug
  //         }
  //         excerpt
  //       }
  //     }
  //   }
  // }

