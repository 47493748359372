import React from "react";
import {Link} from "gatsby";
import styled from "styled-components"
import Img from "gatsby-image";

const PickupContainer = styled.div`
  display: -ms-grid;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 16px;
  justify-items: center;
  margin-bottom: 40px;
  @media (max-width: 950px) {
    display: none;
  }
`
const CardContainer = styled.div`
  width: 940px;
  display: -ms-grid;
  display: grid;
  grid-template-columns: 500px 1fr;
  -ms-grid-columns: 500px 1fr;
  align-items: end;
  a {
    display: block;
    grid-column: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    overflow: hidden;
    text-decoration: none;
    color: black;
    width: 100%;
    img {
      object-fit: cover;
    }
  }
  h2 {
      text-align: left;
    margin: 0;
    line-height: 1.3em;
    font-weight: 300;
  }
  .content {
    grid-column: 2;
    -ms-grid-column: 2;
    -ms-grid-column-span: 2;
    padding: 0 0 0 32px;
    @media (max-width: 700px) {
      grid-column: 1;
      grid-row: 2;
    }
  }  
`

const TitleBox = styled.div`

  grid-column: 2/3;
  grid-row: 2/3;
  width: 100%;
  margin-bottom: 50px;
  h2 {
    text-align: left;
    margin: 0;
    line-height: 1.3em;
    font-weight: 300;
  }
  p {
    text-align: right;
    margin: 0;
  }
 
`;

const Pickup = props => {
  const article = props.data;
  return (
  <PickupContainer>
    <CardContainer>
      <Link to={article.fields.slug}>
        <Img
          fixed={article.fields.thumbnail.desktop.fixed}
          alt={article.fields.title}
          style={{ borderRadius: "16px" }}
        />
      </Link>
      <div className="content">
        <TitleBox>
          <Link to={article.fields.slug}>
            <h2>{article.fields.title}</h2>
          </Link>
          <p>{article.fields.date}</p>
          <p>
            <Link to={`/author/`+article.fields.author}>
              {article.fields.author}
            </Link>
          </p>
        </TitleBox>
      </div>
    </CardContainer>
  </PickupContainer>
)}

export default Pickup;
